export default {
  확인: {
    ko: "확인",
    en: "Confirm",
    id: "Mengonfirmasi",
  },
  등록: {
    ko: "등록",
    en: "Register",
    id: "Daftar",
  },
  등록일시: {
    ko: "등록일시",
    en: "Date and Time of Registration",
    id: "Tanggal dan waktu pendaftaran",
  },
  이름: {
    ko: "이름",
    en: "Name",
    id: "Nama",
  },
  휴대폰번호: {
    ko: "휴대폰번호",
    en: "Mobile No.",
    id: "Nomor ponsel.",
  },
  저장하기: {
    ko: "저장하기",
    en: "Save",
    id: "Menyimpan",
  },
  출고처: {
    ko: "출고처",
    en: "Released by",
    id: "Diterbitkan oleh",
  },
  자동차정보: {
    ko: "자동차정보",
    en: "Car Information",
    id: "Informasi mobil",
  },
  차대번호: {
    ko: "차대번호",
    en: "VIN",
    id: "Vin",
  },
  제조사: {
    ko: "제조사",
    en: "Maker",
    id: "Pembuat",
  },
  모델: {
    ko: "모델",
    en: "Model",
    id: "Model",
  },
  연식: {
    ko: "연식",
    en: "Model Year",
    id: "Tahun model",
  },
  세부모델: {
    ko: "세부모델",
    en: "Specific Model",
    id: "Model spesifik",
  },
  연료: {
    ko: "연료",
    en: "Fuel",
    id: "Bahan bakar",
  },
  소유자: {
    ko: "소유자",
    en: "Owner",
    id: "Pemilik",
  },
  운전자정보: {
    ko: "운전자정보",
    en: "Driver Information",
    id: "Informasi Pengemudi",
  },
  "{value} 명": {
    ko: "{value} 명",
    en: "{value} persons",
    id: "{value} orang",
  },
  "사용자 정보 확인하기": {
    ko: "사용자 정보 확인하기",
    en: "Check User Information",
    id: "Periksa informasi pengguna",
  },
  자동차번호: {
    ko: "자동차번호",
    en: "VIN",
    id: "Vin",
  },
  차종: {
    ko: "차종",
    en: "Vehicle Type",
    id: "Jenis Kendaraan",
  },
  "등록된 자동차정보가 없습니다.": {
    ko: "등록된 자동차정보가 없습니다.",
    en: "Cannot find the car information!",
    id: "Tidak dapat menemukan informasi mobil!",
  },
  "ACHAKEY 설치 여부": {
    ko: "ACHAKEY 설치 여부",
    en: "Achakey Installed or Not",
    id: "Achakey terpasang atau tidak",
  },
  "INFO 설치 여부": {
    ko: "INFO 설치 여부",
    en: "INFO Installed or Not",
    id: "Info yang diinstal atau tidak",
  },
  "오토도어락 활성화 여부": {
    ko: "오토도어락 활성화 여부",
    en: "AUTO Door Lock ON or OFF",
    id: "Kunci pintu mobil hidup atau mati",
  },
  "발렛키 사용 여부": {
    ko: "발렛키 사용 여부",
    en: "Valet Key Used or Not",
    id: "Kunci valet digunakan atau tidak",
  },
  상태: {
    ko: "상태",
    en: "Status",
    id: "Status",
  },
  기간: {
    ko: "기간",
    en: "Period",
    id: "Periode",
  },
  시작날짜: {
    ko: "시작날짜",
    en: "Start Date",
    id: "Mulai tanggal",
  },
  종료날짜: {
    ko: "종료날짜",
    en: "End Date",
    id: "Tanggal akhir",
  },
  "{value}주일": {
    ko: "{value}주일",
    en: "{value} weeks",
    id: "{value} minggu",
  },
  "{value}개월": {
    ko: "{value}개월",
    en: "{value} months",
    id: "{value} bulan",
  },
  부서: {
    ko: "부서",
    en: "Dept.",
    id: "Yg berhubung dgn Departemen",
  },
  이메일: {
    ko: "이메일",
    en: "Email",
    id: "Surel",
  },
  아이디: {
    ko: "아이디",
    en: "ID",
    id: "PENGENAL",
  },
  직급: {
    ko: "직급",
    en: "Job Title",
    id: "Judul pekerjaan",
  },
  "비밀번호를 입력하세요.": {
    ko: "비밀번호를 입력하세요.",
    en: "Please enter a password.",
    id: "Harap masukkan kata sandi.",
  },
  "영문, 숫자, 특수문자 포함 최소 8자리 이상을 입력하세요.": {
    ko: "영문, 숫자, 특수문자 포함 최소 8자리 이상을 입력하세요.",
    en: "A password must have 8 characters or more and contain 1 English letter, 1 number and 1 special character at least.",
    id: "Kata sandi harus memiliki 8 karakter atau lebih dan berisi 1 surat bahasa Inggris, 1 nomor dan 1 karakter khusus setidaknya.",
  },
  "영문, 숫자, 특수문자 포함 최소 8자리 이상": {
    ko: "영문, 숫자, 특수문자 포함 최소 8자리 이상",
    en: "8 characters or more including 1 English letter, 1 number and 1 special character at least",
    id: "8 karakter atau lebih termasuk 1 surat bahasa Inggris, 1 nomor dan 1 karakter khusus setidaknya",
  },
  "비밀번호가 일치하지 않습니다. 다시 입력하세요.": {
    ko: "비밀번호가 일치하지 않습니다. 다시 입력하세요.",
    en: "The password is incorrect. Please enter again.",
    id: "Kata sandi salah. Harap masukkan lagi.",
  },
  "비밀번호를 다시 입력하세요.": {
    ko: "비밀번호를 다시 입력하세요.",
    en: "Please enter the password again.",
    id: "Harap masukkan kata sandi lagi.",
  },
  "해당 사이트는 허가된 사람만 접속할 수 있습니다.": {
    ko: "해당 사이트는 허가된 사람만 접속할 수 있습니다.",
    en: "An authorized person is only permitted to access to the site.",
    id: "Orang yang berwenang hanya diizinkan untuk mengakses Situs.",
  },
  "아이디, 비밀번호를 분실한 경우 관리자에게 문의해주세요.": {
    ko: "아이디, 비밀번호를 분실한 경우 관리자에게 문의해주세요.",
    en: "If ID or password is lost, please contact the admin.",
    id: "Jika ID atau kata sandi hilang, silakan hubungi admin.",
  },
  로그인: {
    ko: "로그인",
    en: "Login",
    id: "Gabung",
  },
  "리스트 목록이 존재하지 않습니다.": {
    ko: "리스트 목록이 존재하지 않습니다.",
    en: "Cannot find the list!",
    id: "Tidak dapat menemukan daftarnya!",
  },
  "업데이트 : {value} 기준": {
    ko: "업데이트 : {value} 기준",
    en: "Update: Based on {value}",
    id: "UPDATE: Berdasarkan {value}",
  },
  새로고침: {
    ko: "새로고침",
    en: "Refresh",
    id: "Menyegarkan",
  },
  검색어: {
    ko: "검색어",
    en: "Keyword",
    id: "Kata kunci",
  },
  "입력하세요.": {
    ko: "입력하세요.",
    en: "Please enter.",
    id: "Harap masuk.",
  },
  검색: {
    ko: "검색",
    en: "Search",
    id: "Mencari",
  },
  "업데이트: ": {
    ko: "업데이트:",
    en: "Update:",
    id: "Memperbarui:",
  },
  "디지털 차키 설치": {
    ko: "디지털 차키 설치",
    en: "Install Digital Car Keys",
    id: "Instal Kunci Mobil Digital",
  },
  "인포 설치": {
    ko: "인포 설치",
    en: "Install INFO",
    id: "Instal info",
  },
  전체: {
    ko: "전체",
    en: "All",
    id: "Semua",
  },
  취소: {
    ko: "취소",
    en: "Cancel",
    id: "Membatalkan",
  },
  "{value1}시간 {value2}분": {
    ko: "{value1}시간 {value2}분",
    en: "{value1} hrs. {value2} min.",
    id: "{value1} jam. {value2} min.",
  },
  "{value}분": {
    ko: "{value}분",
    en: "{value} min.",
    id: "{value} min.",
  },
  홈: {
    ko: "홈",
    en: "Home",
    id: "Rumah",
  },
  "로그아웃 하시겠습니까?": {
    ko: "로그아웃 하시겠습니까?",
    en: "Do you want to log out?",
    id: "Apakah Anda ingin keluar?",
  },
  로그아웃: {
    ko: "로그아웃",
    en: "Log out",
    id: "Keluar",
  },
  "주식회사 튠잇": {
    ko: "주식회사 튠잇",
    en: "TUNEIT CO., LTD.",
    id: "TOLEIT CO., Ltd.",
  },
  "대표자: 송영욱 | 사업자등록번호: 603-88-01354 | 통신판매업신고번호: 제2019-성남분당A-0480":
    {
      ko: "대표자: 송영욱 | 사업자등록번호: 603-88-01354 | 통신판매업신고번호: 제2019-성남분당A-0480",
      en: "CEO: Song Yeong-wook | Business Registration No.: 603-88-01354 | Ecommerce Business Report No.: No.2019-SeongnamBundang A-0480",
      id: "CEO: Song Yeong-wook | Pendaftaran Bisnis No.: 603-88-01354 | Laporan Bisnis Ekommerce No.: No.2019-Seongnambundang A-0480",
    },
  "주소: 경기도 성남시 분당구 느티로 67(정자동) 4층 401-1)": {
    ko: "주소: 경기도 성남시 분당구 느티로 67(정자동) 4층 401-1)",
    en: "Address: 67, Neuti-ro, #401-1 (4F), Bundang-gu, Seongnam-si, Gyeonggi-do",
    id: "Alamat: 67, Neuti-ro, #401-1 (4F), Bundang-gu, Seongnam-si, Gyeonggi-do",
  },
  "대표전화: ": {
    ko: "대표전화:",
    en: "TEL:",
    id: "Tel:",
  },
  "문의메일: ": {
    ko: "문의메일:",
    en: "Email:",
    id: "Surel:",
  },
  "다시 로그인해주세요.": {
    ko: "다시 로그인해주세요.",
    en: "Please log in again.",
    id: "Harap masuk lagi.",
  },
  "일시적인 오류가 발생하였습니다. 잠시후 다시 시도해 주세요.": {
    ko: "일시적인 오류가 발생하였습니다. 잠시후 다시 시도해 주세요.",
    en: "A temporary error has occurred. Please try again later.",
    id: "Kesalahan sementara telah terjadi. Silakan coba lagi nanti.",
  },
  "P(주차)": {
    ko: "P(주차)",
    en: "P (Park)",
    id: "P (taman)",
  },
  "R(후진)": {
    ko: "R(후진)",
    en: "R (Reverse)",
    id: "R (terbalik)",
  },
  "N(중립)": {
    ko: "N(중립)",
    en: "N (Neutral)",
    id: "N (netral)",
  },
  "D(주행)": {
    ko: "D(주행)",
    en: "D (Drive)",
    id: "D (drive)",
  },
  열림: {
    ko: "열림",
    en: "Open",
    id: "Membuka",
  },
  닫힘: {
    ko: "닫힘",
    en: "Close",
    id: "Menutup",
  },
  잠김: {
    ko: "잠김",
    en: "Lock",
    id: "Kunci",
  },
  "자동차 상태는 인포 모듈을 설치한 경우에만 확인 가능합니다": {
    ko: "자동차 상태는 인포 모듈을 설치한 경우에만 확인 가능합니다",
    en: "Unable to check car status unless an INFO module is installed!",
    id: "Tidak dapat memeriksa status mobil kecuali jika modul info diinstal!",
  },
  "업데이트 일시": {
    ko: "업데이트 일시",
    en: "Update Date",
    id: "Tanggal Pembaruan",
  },
  적산거리: {
    ko: "적산거리",
    en: "Odometer",
    id: "Odometer",
  },
  "시동후 주행시간": {
    ko: "시동후 주행시간",
    en: "Travel time after starting the engine",
    id: "Waktu perjalanan setelah menyalakan mesin",
  },
  "시동후 주행거리": {
    ko: "시동후 주행거리",
    en: "Mileage after starting the engine",
    id: "Jarak tempuh setelah menyalakan mesin",
  },
  배터리상태: {
    ko: "배터리상태",
    en: "Battery Status",
    id: "Status baterai",
  },
  배터리충전률: {
    ko: "배터리충전률",
    en: "Battery Level",
    id: "Level baterai",
  },
  배터리전압: {
    ko: "배터리전압",
    en: "Battery Voltage",
    id: "Tegangan baterai",
  },
  잔여연료량: {
    ko: "잔여연료량",
    en: "Fuel Level",
    id: "Level Bahan Bakar",
  },
  시동상태: {
    ko: "시동상태",
    en: "Start Status",
    id: "Status Mulai",
  },
  속도: {
    ko: "속도",
    en: "Speed",
    id: "Kecepatan",
  },
  기어상태: {
    ko: "기어상태",
    en: "Transmission Status",
    id: "Status transmisi",
  },
  운전지수: {
    ko: "운전지수",
    en: "Driving Index",
    id: "Indeks mengemudi",
  },
  급가속: {
    ko: "급가속",
    en: "Sudden Acceleration",
    id: "Akselerasi mendadak",
  },
  급감속: {
    ko: "급감속",
    en: "Sudden Deceleration",
    id: "Perlambatan mendadak",
  },
  안전운전점수: {
    ko: "안전운전점수",
    en: "Safety Driving Score",
    id: "Skor mengemudi keamanan",
  },
  냉각수온도: {
    ko: "냉각수온도",
    en: "Anti-freeze Coolant Temperature",
    id: "Suhu pendingin anti-beku",
  },
  엔진오일온도: {
    ko: "엔진오일온도",
    en: "Engine Oil Temperature",
    id: "Suhu oli mesin",
  },
  미션오일온도: {
    ko: "미션오일온도",
    en: "Transmission Oil Temperature",
    id: "Suhu oli transmisi",
  },
  "1열 좌측 도어": {
    ko: "1열 좌측 도어",
    en: "Front-row Left Door",
    id: "Pintu kiri barisan depan",
  },
  "2열 좌측 도어": {
    ko: "2열 좌측 도어",
    en: "Rear-row Left Door",
    id: "Pintu kiri baris belakang",
  },
  "1열 우측 도어": {
    ko: "1열 우측 도어",
    en: "Front-row Right Door",
    id: "Pintu kanan barisan depan",
  },
  "2열 우측 도어": {
    ko: "2열 우측 도어",
    en: "Rear-row Right Door",
    id: "Pintu kanan belakang",
  },
  "도어 잠금": {
    ko: "도어 잠금",
    en: "Door Lock",
    id: "Kunci pintu",
  },
  "도어 잠금 해제": {
    ko: "도어 잠금 해제",
    en: "Door Unlock",
    id: "Membuka kunci pintu",
  },
  "원격 도어 제어 기능은 긴급상황에서만 사용해야 합니다.": {
    ko: "원격 도어 제어 기능은 긴급상황에서만 사용해야 합니다.",
    en: "The remote control door lock should be used at emergency situations only.",
    id: "Kunci pintu remote control harus digunakan hanya pada situasi darurat.",
  },
  "해당 기능으로 도어 제어시 반드시 고객의 동의를 받아야 합니다.": {
    ko: "해당 기능으로 도어 제어시 반드시 고객의 동의를 받아야 합니다.",
    en: "You must obtain consent from the client to control the door with this option.",
    id: "Anda harus mendapatkan persetujuan dari klien untuk mengontrol pintu dengan opsi ini.",
  },
  "디바이스 정보": {
    ko: "디바이스 정보",
    en: "Device Information",
    id: "Informasi perangkat",
  },
  "디바이스 등록 이력": {
    ko: "디바이스 등록 이력",
    en: "Device Addition History",
    id: "Riwayat penambahan perangkat",
  },
  "자동차 상태": {
    ko: "자동차 상태",
    en: "Car Status",
    id: "Status mobil",
  },
  "월간 리포트": {
    ko: "월간 리포트",
    en: "Monthly Report",
    id: "Laporan bulanan",
  },
  제목: {
    ko: "제목",
    en: "Title",
    id: "Judul",
  },
  발행일시: {
    ko: "발행일시",
    en: "Issue Date",
    id: "Tanggal pembuatan",
  },
  발송일시: {
    ko: "발송일시",
    en: "Date Forwarded",
    id: "Tanggal diteruskan",
  },
  "디바이스 등록 이력이 없습니다.": {
    ko: "디바이스 등록 이력이 없습니다.",
    en: "Cannot find any device addition history!",
    id: "Tidak dapat menemukan riwayat penambahan perangkat apa pun!",
  },
  삭제: {
    ko: "삭제",
    en: "Delete",
    id: "Menghapus",
  },
  삭제일시: {
    ko: "삭제일시",
    en: "Date Deleted",
    id: "Tanggal dihapus",
  },
  안내메일발송: {
    ko: "안내메일발송",
    en: "Send Notification Mail",
    id: "Kirim surat pemberitahuan",
  },
  "상세 정보 확인하기": {
    ko: "상세 정보 확인하기",
    en: "Check Details",
    id: "Periksa detail",
  },
  시리얼: {
    ko: "시리얼",
    en: "Serial",
    id: "Serial",
  },
  키배열: {
    ko: "키배열",
    en: "Key Array",
    id: "Array kunci",
  },
  분류: {
    ko: "분류",
    en: "Classification",
    id: "Klasifikasi",
  },
  공유일시: {
    ko: "공유일시",
    en: "Date and Time of Share",
    id: "Tanggal dan waktu berbagi",
  },
  "사용자 정보": {
    ko: "사용자 정보",
    en: "User Information",
    id: "informasi pengguna",
  },
  "사용자가 없습니다.": {
    ko: "사용자가 없습니다.",
    en: "Cannot find the user!",
    id: "Tidak dapat menemukan pengguna!",
  },
  "좀더 상세한 디바이스 정보는 디바이스 관리에서 Mac, 시리얼을 이용해서 조회후 확인할 수 있습니다.":
    {
      ko: "좀더 상세한 디바이스 정보는 디바이스 관리에서 Mac, 시리얼을 이용해서 조회후 확인할 수 있습니다.",
      en: "You can check more information about the device in the DEVICE MANAGEMENT, using MAC and serial number.",
      id: "Anda dapat memeriksa informasi lebih lanjut tentang perangkat dalam manajemen perangkat, menggunakan Mac dan nomor seri.",
    },
  자동차정보상세: {
    ko: "자동차정보상세",
    en: "Car Information Details",
    id: "Detail Informasi Mobil",
  },
  추가정보: {
    ko: "추가정보",
    en: "Additional Information",
    id: "informasi tambahan",
  },
  오토도어락: {
    ko: "오토도어락",
    en: "AUTO Door Lock",
    id: "Kunci Pintu Otomatis",
  },
  "ON(활성화)": {
    ko: "ON(활성화)",
    en: "ON",
    id: "PADA",
  },
  "OFF(비활성화)": {
    ko: "OFF(비활성화)",
    en: "OFF",
    id: "MATI",
  },
  안심잠금: {
    ko: "안심잠금",
    en: "Safety Lock",
    id: "Kunci keamanan",
  },
  "Y(사용중)": {
    ko: "Y(사용중)",
    en: "Y (currently in use)",
    id: "Y (saat ini digunakan)",
  },
  "N(미사용중)": {
    ko: "N(미사용중)",
    en: "N (currently unused)",
    id: "N (saat ini tidak digunakan)",
  },
  "확인이 필요한 이력을 조회하세요.": {
    ko: "확인이 필요한 이력을 조회하세요.",
    en: "Please search the history.",
    id: "Harap cari sejarahnya.",
  },
  제어이력조회: {
    ko: "제어이력조회",
    en: "Control History Search",
    id: "Pencarian Sejarah Kontrol",
  },
  주행이력조회: {
    ko: "주행이력조회",
    en: "Driving History Search",
    id: "Pencarian riwayat mengemudi",
  },
  공유이력조회: {
    ko: "공유이력조회",
    en: "Share History Search",
    id: "Bagikan pencarian riwayat",
  },
  "0 명": {
    ko: "0 명",
    en: "0 person(s)",
    id: "0 orang",
  },
  디바이스: {
    ko: "디바이스",
    en: "Device",
    id: "Perangkat",
  },
  부가기능: {
    ko: "부가기능",
    en: "Additional Features",
    id: "Fitur tambahan",
  },
  "CONNECTED 설치 여부": {
    ko: "CONNECTED 설치 여부",
    en: "CONNECTED Installed or Not",
    id: "Terhubung terpasang atau tidak",
  },
  회원: {
    ko: "회원",
    en: "Member",
    id: "Anggota",
  },
  위치: {
    ko: "위치",
    en: "Location",
    id: "Lokasi",
  },
  반납일시: {
    ko: "반납일시",
    en: "Date and Time of Return",
    id: "Tanggal dan waktu pengembalian",
  },
  이용시간: {
    ko: "이용시간",
    en: "Time Used",
    id: "Waktu digunakan",
  },
  운행정보: {
    ko: "운행정보",
    en: "Driving Information",
    id: "Informasi mengemudi",
  },
  운행중: {
    ko: "운행중",
    en: "Driving…",
    id: "Menyetir…",
  },
  "{value}회 주행": {
    ko: "{value}회 주행",
    en: "Used {value} times",
    id: "Digunakan {value} kali",
  },
  출발지: {
    ko: "출발지",
    en: "Departure",
    id: "Keberangkatan",
  },
  도착지: {
    ko: "도착지",
    en: "Arrival",
    id: "Kedatangan",
  },
  출발일시: {
    ko: "출발일시",
    en: "Departure Date and Time",
    id: "Tanggal dan waktu keberangkatan",
  },
  도착일시: {
    ko: "도착일시",
    en: "Arrival Date and Time",
    id: "Tanggal dan Waktu Kedatangan",
  },
  주행시간: {
    ko: "주행시간",
    en: "Travel Time",
    id: "Waktu perjalanan",
  },
  주행거리: {
    ko: "주행거리",
    en: "Mileage",
    id: "Jarak tempuh",
  },
  주행경로: {
    ko: "주행경로",
    en: "Driving Path",
    id: "Jalur mengemudi",
  },
  제어항목: {
    ko: "제어항목",
    en: "Control Item",
    id: "Item kontrol",
  },
  요청일시: {
    ko: "요청일시",
    en: "Request Date",
    id: "Tanggal Permintaan",
  },
  응답일시: {
    ko: "응답일시",
    en: "Response Date",
    id: "Tanggal respons",
  },
  위치정보: {
    ko: "위치정보",
    en: "Location Information",
    id: "informasi lokasi",
  },
  지도보기: {
    ko: "지도보기",
    en: "View Map",
    id: "Lihat peta",
  },
  어드민: {
    ko: "어드민",
    en: "Admin",
    id: "Admin",
  },
  유통사: {
    ko: "유통사",
    en: "Distributor",
    id: "Distributor",
  },
  운영사: {
    ko: "운영사",
    en: "Operator",
    id: "Operator",
  },
  "BIZ(렌트)": {
    ko: "BIZ(렌트)",
    en: "BIZ (Rental)",
    id: "Biz (sewa)",
  },
  고객사: {
    ko: "고객사",
    en: "Client",
    id: "Klien",
  },
  "올바른 양식입니다.": {
    ko: "올바른 양식입니다.",
    en: "A correct form!",
    id: "Bentuk yang benar!",
  },
  "- 를 제외한 숫자 10자리를 입력해주세요.": {
    ko: "- 를 제외한 숫자 10자리를 입력해주세요.",
    en: "Please enter a 10-digit number without hyphens.",
    id: "Harap masukkan nomor 10 digit tanpa tanda hubung.",
  },
  파트너유형: {
    ko: "파트너유형",
    en: "Partner Type",
    id: "Jenis pasangan",
  },
  "파트너 아이디": {
    ko: "파트너 아이디",
    en: "Partner ID",
    id: "ID Mitra",
  },
  "파트너 아이디를 입력하세요.": {
    ko: "파트너 아이디를 입력하세요.",
    en: "Please enter a partner’s ID.",
    id: "Harap masukkan ID mitra.",
  },
  기업명: {
    ko: "기업명",
    en: "Company Name",
    id: "Nama perusahaan",
  },
  "기업명을 입력하세요.": {
    ko: "기업명을 입력하세요.",
    en: "Please enter the company name.",
    id: "Harap masukkan nama perusahaan.",
  },
  법인등록번호: {
    ko: "법인등록번호",
    en: "Corporation Registration No.",
    id: "No. Pendaftaran Korporasi",
  },
  "법인등록번호를 입력하세요.": {
    ko: "법인등록번호를 입력하세요.",
    en: "Please enter the Corporation Registration No.",
    id: "Harap masukkan No. Registrasi Korporasi",
  },
  사업자등록번호: {
    ko: "사업자등록번호",
    en: "Business Registration No.",
    id: "Pendaftaran Bisnis No.",
  },
  대표자: {
    ko: "대표자",
    en: "CEO",
    id: "CEO",
  },
  "대표자를 입력하세요.": {
    ko: "대표자를 입력하세요.",
    en: "Please enter the CEO’s name.",
    id: "Harap masukkan nama CEO.",
  },
  대표번호: {
    ko: "대표번호",
    en: "TEL",
    id: "Tel",
  },
  "대표번호를 입력해주세요.": {
    ko: "대표번호를 입력해주세요.",
    en: "Please enter the phone number.",
    id: "Harap masukkan nomor telepon.",
  },
  팩스번호: {
    ko: "팩스번호",
    en: "FAX",
    id: "FAX",
  },
  "팩스번호를 입력해주세요.": {
    ko: "팩스번호를 입력해주세요.",
    en: "Please enter the phone number.",
    id: "Harap masukkan nomor telepon.",
  },
  문의번호: {
    ko: "문의번호",
    en: "Inquiry",
    id: "Pertanyaan",
  },
  "문의번호를 입력해주세요.": {
    ko: "문의번호를 입력해주세요.",
    en: "Please enter the inquiry number.",
    id: "Harap masukkan nomor pertanyaan.",
  },
  "주소 검색": {
    ko: "주소 검색",
    en: "Address Search",
    id: "Pencarian alamat",
  },
  "파트너정보 수정이 정상적으로 완료되었습니다.": {
    ko: "파트너정보 수정이 정상적으로 완료되었습니다.",
    en: "The partner information is successfully edited.",
    id: "Informasi mitra berhasil diedit.",
  },
  "파트너정보에서 확인하세요.": {
    ko: "파트너정보에서 확인하세요.",
    en: "Please check in the Partner Information.",
    id: "Silakan periksa informasi mitra.",
  },
  "파트너아이디는 고유키로 등록 후 수정이 불가능합니다.": {
    ko: "파트너아이디는 고유키로 등록 후 수정이 불가능합니다.",
    en: "A partner ID can be registered with its own key. Then, it cannot be edited.",
    id: "ID mitra dapat didaftarkan dengan kuncinya sendiri. Kemudian, itu tidak dapat diedit.",
  },
  "기업정보는 법인등록번호를 기준으로 중복 등록이 불가능합니다.": {
    ko: "기업정보는 법인등록번호를 기준으로 중복 등록이 불가능합니다.",
    en: "The company information cannot be registered repeatedly under Corporation Registration No.",
    id: "Informasi Perusahaan tidak dapat didaftarkan berulang kali di bawah No. Registrasi Korporasi",
  },
  "정보를 불러오지 못했습니다.": {
    ko: "정보를 불러오지 못했습니다.",
    en: "Failed to load information!",
    id: "Gagal memuat informasi!",
  },
  "사업자등록증이 없습니다.": {
    ko: "사업자등록증이 없습니다.",
    en: "Cannot find the Certificate of Business Registration!",
    id: "Tidak dapat menemukan sertifikat pendaftaran bisnis!",
  },
  "고객사 사업자등록증": {
    ko: "고객사 사업자등록증",
    en: "Client’s Certificate of Business Registration",
    id: "Sertifikat Pendaftaran Bisnis Klien",
  },
  "파트너 사업자등록증을 PDF로 등록해주세요.": {
    ko: "파트너 사업자등록증을 PDF로 등록해주세요.",
    en: "Please add a partner’s Certificate of Business Registration in PDF file format.",
    id: "Harap tambahkan sertifikat pendaftaran bisnis mitra dalam format file PDF.",
  },
  "등록된 문서는 파트너 운영자가 어드민을 통해 확인할 수 있습니다.": {
    ko: "등록된 문서는 파트너 운영자가 어드민을 통해 확인할 수 있습니다.",
    en: "The partner operator is able to check the registered documents via the admin.",
    id: "Operator mitra dapat memeriksa dokumen terdaftar melalui admin.",
  },
  "기존 등록 정보가 수정되면 저장하기가 가능합니다.": {
    ko: "기존 등록 정보가 수정되면 저장하기가 가능합니다.",
    en: "Once the registered information is edited, it can be saved.",
    id: "Setelah informasi terdaftar diedit, dapat disimpan.",
  },
  "수정한 내용에 이상이 없는지 확인 후 저장을 진행하세요.": {
    ko: "수정한 내용에 이상이 없는지 확인 후 저장을 진행하세요.",
    en: "Check if the information is correctly edited and then save.",
    id: "Periksa apakah informasi diedit dengan benar dan kemudian simpan.",
  },
  업태: {
    ko: "업태",
    en: "Business Type",
    id: "Jenis bisnis",
  },
  "업태를 입력하세요.": {
    ko: "업태를 입력하세요.",
    en: "Please select a business type.",
    id: "Pilih jenis bisnis.",
  },
  종목: {
    ko: "종목",
    en: "Business Item",
    id: "Item bisnis",
  },
  "종목을 입력하세요.": {
    ko: "종목을 입력하세요.",
    en: "Please select a business item.",
    id: "Pilih item bisnis.",
  },
  우편번호: {
    ko: "우편번호",
    en: "Zip Code",
    id: "Kode Pos",
  },
  "우편번호를 입력하세요.": {
    ko: "우편번호를 입력하세요.",
    en: "Please enter a zip code.",
    id: "Harap masukkan kode pos.",
  },
  주소: {
    ko: "주소",
    en: "Address",
    id: "Alamat",
  },
  "주소를 입력하세요.": {
    ko: "주소를 입력하세요.",
    en: "Please enter an address.",
    id: "Harap masukkan alamat.",
  },
  "작성중인 내용이 있습니다.": {
    ko: "작성중인 내용이 있습니다.",
    en: "A text which is being written is found.",
    id: "Teks yang sedang ditulis ditemukan.",
  },
  "뒤로가기 시 작성중인 내용이 저장되지 않습니다.": {
    ko: "뒤로가기 시 작성중인 내용이 저장되지 않습니다.",
    en: "Once the PREVIOUS button is clicked, the text being written will not be saved.",
    id: "Setelah tombol sebelumnya diklik, teks yang ditulis tidak akan disimpan.",
  },
  "작성을 취소하시겠어요?": {
    ko: "작성을 취소하시겠어요?",
    en: "Do you want to cancel?",
    id: "Apakah Anda ingin membatalkan?",
  },
  아니오: {
    ko: "아니오",
    en: "No",
    id: "TIDAK",
  },
  네: {
    ko: "네",
    en: "Yes",
    id: "Ya",
  },
  수정하기: {
    ko: "수정하기",
    en: "Edit",
    id: "Edit",
  },
  "이름은 한글 혹은 영어만 가능합니다.": {
    ko: "이름은 한글 혹은 영어만 가능합니다.",
    en: "A name can be in either Korean or English only.",
    id: "Nama bisa dalam bahasa Korea atau bahasa Inggris saja.",
  },
  "이메일을 정확하게 입력해주세요.": {
    ko: "이메일을 정확하게 입력해주세요.",
    en: "Please enter the email address correctly.",
    id: "Harap masukkan alamat email dengan benar.",
  },
  "휴대폰 번호는 ": {
    ko: "휴대폰 번호는",
    en: "A mobile number is",
    id: "Nomor ponsel adalah",
  },
  "등록 완료": {
    ko: "등록 완료",
    en: "Successfully registered!",
    id: "Pendaftaran berhasil!",
  },
  "엔지니어 등록이 완료되었습니다.": {
    ko: "엔지니어 등록이 완료되었습니다.",
    en: "The engineer is successfully registered.",
    id: "Insinyur berhasil terdaftar.",
  },
  "엔지니어정보 메뉴에서 등록된 정보를 확인할 수 있습니다.": {
    ko: "엔지니어정보 메뉴에서 등록된 정보를 확인할 수 있습니다.",
    en: "The information can be checked in the ENGINEER INFORMATION.",
    id: "Informasi dapat diperiksa dalam informasi insinyur.",
  },
  "엔지니어 등록하기": {
    ko: "엔지니어 등록하기",
    en: "Add Engineer",
    id: "Tambahkan insinyur",
  },
  파트너명: {
    ko: "파트너명",
    en: "Partner Name",
    id: "Nama pasangan",
  },
  "이름을 입력하세요.": {
    ko: "이름을 입력하세요.",
    en: "Please enter the name.",
    id: "Harap masukkan namanya.",
  },
  "부서를 입력하세요.": {
    ko: "부서를 입력하세요.",
    en: "Please enter the department.",
    id: "Harap masukkan departemen.",
  },
  직책: {
    ko: "직책",
    en: "Job Title",
    id: "Judul pekerjaan",
  },
  "직책을 입력하세요.": {
    ko: "직책을 입력하세요.",
    en: "Please enter the job title.",
    id: "Harap masukkan judul pekerjaan.",
  },
  "이메일을 입력하세요.": {
    ko: "이메일을 입력하세요.",
    en: "Please enter the email address.",
    id: "Harap masukkan alamat email.",
  },
  "휴대폰 번호": {
    ko: "휴대폰 번호",
    en: "Mobile No.",
    id: "Nomor ponsel.",
  },
  "- 를 제외한 휴대폰 번호를 입력하세요.": {
    ko: "- 를 제외한 휴대폰 번호를 입력하세요.",
    en: "Please enter your mobile number without hyphens.",
    id: "Harap masukkan nomor ponsel Anda tanpa tanda hubung.",
  },
  "엔지니어 등록 후 사용자에게 엔지니어 앱을 다운로드하여 설치하도록 안내해주세요.":
    {
      ko: "엔지니어 등록 후 사용자에게 엔지니어 앱을 다운로드하여 설치하도록 안내해주세요.",
      en: "Once the engineer is added, guide the user to download and install an Engineer app.",
      id: "Setelah insinyur ditambahkan, bimbing pengguna untuk mengunduh dan menginstal aplikasi insinyur.",
    },
  "사용자는 엔지니어 앱을 실행하여 본인인증을 완료하면 정상적으로 엔지니어 앱을 사용할 수 있습니다.":
    {
      ko: "사용자는 엔지니어 앱을 실행하여 본인인증을 완료하면 정상적으로 엔지니어 앱을 사용할 수 있습니다.",
      en: "Run the Engineer app and complete user authentication. Then, you are able to use the app.",
      id: "Jalankan aplikasi insinyur dan selesaikan otentikasi pengguna. Kemudian, Anda dapat menggunakan aplikasi.",
    },
  "엔지니어 등록": {
    ko: "엔지니어 등록",
    en: "Add Engineer",
    id: "Tambahkan insinyur",
  },
  "검색된 결과가 없습니다.": {
    ko: "검색된 결과가 없습니다.",
    en: "No results are found!",
    id: "Tidak ada hasil yang ditemukan!",
  },
  인증: {
    ko: "인증",
    en: "Authentication",
    id: "Autentikasi",
  },
  "엔지니어정보 수정이 완료되었습니다.": {
    ko: "엔지니어정보 수정이 완료되었습니다.",
    en: "The engineer information is successfully edited.",
    id: "Informasi Insinyur berhasil diedit.",
  },
  "엔지니어정보 메뉴에서 수정된 정보를 확인할 수 있습니다.": {
    ko: "엔지니어정보 메뉴에서 수정된 정보를 확인할 수 있습니다.",
    en: "The information can be checked in the ENGINEER INFORMATION.",
    id: "Informasi dapat diperiksa dalam informasi insinyur.",
  },
  "엔지니어 정보": {
    ko: "엔지니어 정보",
    en: "Engineer Information",
    id: "Informasi Insinyur",
  },
  파트너: {
    ko: "파트너",
    en: "Partner",
    id: "Mitra",
  },
  검색하세요: {
    ko: "검색하세요",
    en: "Please search.",
    id: "Harap cari.",
  },
  "휴대폰 번호를 입력하세요.": {
    ko: "휴대폰 번호를 입력하세요.",
    en: "Please enter your mobile No.",
    id: "Harap masukkan No. Seluler Anda",
  },
  "등록 일시": {
    ko: "등록 일시",
    en: "Date and Time Registered",
    id: "Tanggal dan waktu terdaftar",
  },
  "인증 일시": {
    ko: "인증 일시",
    en: "Date and Time Authenticated",
    id: "Tanggal dan waktu diautentikasi",
  },
  정상: {
    ko: "정상",
    en: "Normal",
    id: "Normal",
  },
  중지: {
    ko: "중지",
    en: "Suspended",
    id: "Tergantung",
  },
  퇴사: {
    ko: "퇴사",
    en: "Resigned",
    id: "Mengundurkan diri",
  },
  "*상태를 퇴사로 변경하면 정상으로 복구가 불가능합니다.": {
    ko: "*상태를 퇴사로 변경하면 정상으로 복구가 불가능합니다.",
    en: "*Once the status is set to ‘Resigned’, it cannot be recovered.",
    id: "*Setelah status diatur untuk 'mengundurkan diri', itu tidak dapat dipulihkan.",
  },
  "퇴사 처리되었습니다.": {
    ko: "퇴사 처리되었습니다.",
    en: "Successfully resigned!",
    id: "Berhasil mengundurkan diri!",
  },
  "아이디는 4글자 이상 설정해야 합니다.": {
    ko: "아이디는 4글자 이상 설정해야 합니다.",
    en: "An ID should be at least 4-character long.",
    id: "ID harus setidaknya 4 karakter.",
  },
  "비밀번호는 최소 8자, 영문, 숫자, 특수문자를 포함해야 합니다.": {
    ko: "비밀번호는 최소 8자, 영문, 숫자, 특수문자를 포함해야 합니다.",
    en: "A password must have 8 characters or more and contain 1 English letter, 1 number and 1 special character at least.",
    id: "Kata sandi harus memiliki 8 karakter atau lebih dan berisi 1 surat bahasa Inggris, 1 nomor dan 1 karakter khusus setidaknya.",
  },
  "관리자 등록이 완료되었습니다.": {
    ko: "관리자 등록이 완료되었습니다.",
    en: "Admin registration is completed.",
    id: "Pendaftaran admin selesai.",
  },
  "관리자정보 메뉴에서 등록된 정보를 확인할 수 있습니다.": {
    ko: "관리자정보 메뉴에서 등록된 정보를 확인할 수 있습니다.",
    en: "The information can be checked in the ADMIN INFORMATION.",
    id: "Informasi dapat diperiksa dalam informasi admin.",
  },
  "파트너 어드민 접속시 사용할 아이디를 입력하세요.": {
    ko: "파트너 어드민 접속시 사용할 아이디를 입력하세요.",
    en: "Please enter an ID to be used to access to the Partner Admin.",
    id: "Harap masukkan ID untuk digunakan untuk mengakses admin mitra.",
  },
  비밀번호: {
    ko: "비밀번호",
    en: "Password",
    id: "Kata sandi",
  },
  "파트너 어드민 접속시 사용할 비밀번호를 입력하세요.": {
    ko: "파트너 어드민 접속시 사용할 비밀번호를 입력하세요.",
    en: "Please enter a password to be used to access to the Partner Admin.",
    id: "Harap masukkan kata sandi yang akan digunakan untuk mengakses admin mitra.",
  },
  "비밀번호 확인": {
    ko: "비밀번호 확인",
    en: "Confirm Password",
    id: "konfirmasi sandi",
  },
  "입력한 비밀번호를 다시 입력해주세요.": {
    ko: "입력한 비밀번호를 다시 입력해주세요.",
    en: "Please enter the password again.",
    id: "Harap masukkan kata sandi lagi.",
  },
  "비밀번호가 틀립니다. 다시 입력하세요.": {
    ko: "비밀번호가 틀립니다. 다시 입력하세요.",
    en: "The password is incorrect. Please enter again.",
    id: "Kata sandi salah. Harap masukkan lagi.",
  },
  "아이디는 최소 4자리 이상 설정해야 합니다.": {
    ko: "아이디는 최소 4자리 이상 설정해야 합니다.",
    en: "An ID should be at least 4-charater long.",
    id: "ID harus memiliki panjang setidaknya 4-karakter.",
  },
  "비밀번호는 최소 8자리 이상 영문자, 숫자, 특수문자를 포함해야 합니다.": {
    ko: "비밀번호는 최소 8자리 이상 영문자, 숫자, 특수문자를 포함해야 합니다.",
    en: "A password must have 8 characters or more and contain 1 English letter, 1 number and 1 special character at least.",
    id: "Kata sandi harus memiliki 8 karakter atau lebih dan berisi 1 surat bahasa Inggris, 1 nomor dan 1 karakter khusus setidaknya.",
  },
  "*파트너에서 사용할 관리자 계정을 등록 생성합니다.": {
    ko: "*파트너에서 사용할 관리자 계정을 등록 생성합니다.",
    en: "An admin account to be used in the PARTNER is added.",
    id: "Akun admin yang akan digunakan dalam mitra ditambahkan.",
  },
  "관리자 등록하기": {
    ko: "관리자 등록하기",
    en: "Add Admin",
    id: "Tambahkan admin",
  },
  "파트너 검색": {
    ko: "파트너 검색",
    en: "Search Partner",
    id: "Mitra pencarian",
  },
  정지: {
    ko: "정지",
    en: "Stop",
    id: "Berhenti",
  },
  "수정 완료": {
    ko: "수정 완료",
    en: "Successfully edited!",
    id: "Berhasil diedit!",
  },
  "관리자 정보 수정이 완료되었습니다.": {
    ko: "관리자 정보 수정이 완료되었습니다.",
    en: "The admin information is successfully edited.",
    id: "Informasi admin berhasil diedit.",
  },
  "관리자정보 메뉴에서 수정된 정보를 확인할 수 있습니다.": {
    ko: "관리자정보 메뉴에서 수정된 정보를 확인할 수 있습니다.",
    en: "The information can be checked in the ADMIN INFORMATION.",
    id: "Informasi dapat diperiksa dalam informasi admin.",
  },
  "비밀번호 재설정 링크가 포함된 메일이 발송되었습니다.": {
    ko: "비밀번호 재설정 링크가 포함된 메일이 발송되었습니다.",
    en: "A mail with a password reset link has been sent.",
    id: "Surat dengan tautan reset kata sandi telah dikirim.",
  },
  "해당 메일에 포함된 재설정 링크는 24시간만 유효합니다.": {
    ko: "해당 메일에 포함된 재설정 링크는 24시간만 유효합니다.",
    en: "The link is valid for 24 hours.",
    id: "Tautan ini berlaku selama 24 jam.",
  },
  "최근 로그인": {
    ko: "최근 로그인",
    en: "Recent Login",
    id: "Login Terbaru",
  },
  "비밀번호 재설정 링크는 발송 후 24시간만 유효합니다.": {
    ko: "비밀번호 재설정 링크는 발송 후 24시간만 유효합니다.",
    en: "The password reset link is valid for 24 hours.",
    id: "Tautan reset kata sandi berlaku selama 24 jam.",
  },
  "담당자 정보 수정이 필요하면 수정하기 버튼을 클릭하세요.": {
    ko: "담당자 정보 수정이 필요하면 수정하기 버튼을 클릭하세요.",
    en: "To change the admin information, please click [Edit].",
    id: "Untuk mengubah informasi admin, silakan klik [Edit].",
  },
  "상태를 퇴사로 변경하면 정상으로 복구가 불가능합니다.": {
    ko: "상태를 퇴사로 변경하면 정상으로 복구가 불가능합니다.",
    en: "Once the status is set to ‘Resigned’, it cannot be recovered.",
    id: "Setelah status diatur ke 'pasrah', itu tidak dapat dipulihkan.",
  },
  "비밀번호 재설정(링크) 메일발송": {
    ko: "비밀번호 재설정(링크) 메일발송",
    en: "Send Password Reset (Link) Mail",
    id: "Kirim Kata Sandi Reset (Tautan) Surat",
  },
  관리자이름: {
    ko: "관리자이름",
    en: "Admin Information",
    id: "Informasi Admin",
  },
  관리자ID: {
    ko: "관리자ID",
    en: "Admin ID",
    id: "ID Admin",
  },
  "등록된 관리자 정보가 없습니다.": {
    ko: "등록된 관리자 정보가 없습니다.",
    en: "Cannot find the admin information!",
    id: "Tidak dapat menemukan informasi admin!",
  },
  "이용메뉴얼 보기": {
    ko: "이용메뉴얼 보기",
    en: "View User Manual",
    id: "Lihat Manual Pengguna",
  },
  "제목을 입력해주세요.": {
    ko: "제목을 입력해주세요.",
    en: "Please enter the title.",
    id: "Harap masukkan judulnya.",
  },
  이전글: {
    ko: "이전글",
    en: "Previous",
    id: "Sebelumnya",
  },
  다음글: {
    ko: "다음글",
    en: "Next",
    id: "Berikutnya",
  },
  "공지사항 게시글": {
    ko: "공지사항 게시글",
    en: "Post on the Notices",
    id: "Posting di pemberitahuan",
  },
  본문: {
    ko: "본문",
    en: "Texts",
    id: "Teks",
  },
  "기업 아이디를 입력하세요.": {
    ko: "기업 아이디를 입력하세요.",
    en: "Please enter a company ID.",
    id: "Harap masukkan ID perusahaan.",
  },
  "시스템에 등록된 본인의 이름과 이메일을 입력하세요.": {
    ko: "시스템에 등록된 본인의 이름과 이메일을 입력하세요.",
    en: "Please enter your name and email address registered in the system.",
    id: "Harap masukkan nama dan alamat email Anda yang terdaftar di sistem.",
  },
  "등록된 정보가 정확하지 않은 경우 관리자에게 문의하세요.": {
    ko: "등록된 정보가 정확하지 않은 경우 관리자에게 문의하세요.",
    en: "If the information is incorrect, feel free to contact the admin.",
    id: "Jika informasinya salah, jangan ragu untuk menghubungi admin.",
  },
  "아이디를 입력해주세요.": {
    ko: "아이디를 입력해주세요.",
    en: "Please enter your ID.",
    id: "Harap masukkan ID Anda.",
  },
  "비밀번호를 입력해주세요.": {
    ko: "비밀번호를 입력해주세요.",
    en: "Please enter your password.",
    id: "Harap masukkan kata sandi Anda.",
  },
  "Internet Explorer는 호환되지 않는 브라우저 입니다.\\nChrome 브라우저를 이용해주십시요.":
    {
      ko: "Internet Explorer는 호환되지 않는 브라우저 입니다.\\nChrome 브라우저를 이용해주십시요.",
      en: "This browser is not compatible with Internet Explorer. \\n Please use Chrome.",
      id: "Browser ini tidak kompatibel dengan Internet Explorer. \\ n tolong gunakan chrome.",
    },
  "자동차 이용 현황": {
    ko: "자동차 이용 현황",
    en: "Current Vehicle Usage",
    id: "Penggunaan kendaraan saat ini",
  },
  "누적 자동차 수": {
    ko: "누적 자동차 수",
    en: "Cumulative No. of Vehicles",
    id: "Kumulatif Jumlah Kendaraan",
  },
  "누적 주행거리": {
    ko: "누적 주행거리",
    en: "Cumulative Mileage",
    id: "Jarak tempuh kumulatif",
  },
  "누적 주행 횟수": {
    ko: "누적 주행 횟수",
    en: "Cumulative No. of Car Usage",
    id: "Kumulatif Jumlah Penggunaan Mobil",
  },
  "누적 주행 시간": {
    ko: "누적 주행 시간",
    en: "Cumulative Travel Time",
    id: "Waktu perjalanan kumulatif",
  },
  "평균 주행거리": {
    ko: "평균 주행거리",
    en: "Average Mileage",
    id: "Jarak tempuh rata -rata",
  },
  "평균 주행 횟수": {
    ko: "평균 주행 횟수",
    en: "Average No. of Car Usage",
    id: "Rata -rata Jumlah Penggunaan Mobil",
  },
  "평균 주행 시간": {
    ko: "평균 주행 시간",
    en: "Average Travel Time",
    id: "Waktu perjalanan rata -rata",
  },
  "1년차 자동차 수": {
    ko: "1년차 자동차 수",
    en: "No. of Vehicles on Year 1",
    id: "Jumlah Kendaraan di Tahun 1",
  },
  "1년차 자동차": {
    ko: "1년차 자동차",
    en: "Vehicles on Year 1",
    id: "Kendaraan di Tahun 1",
  },
  "누적 자동차 수는 모빌리티 솔루션이 설치되어 시스템에 등록된 전체 자동차 수를 의미합니다.":
    {
      ko: "누적 자동차 수는 모빌리티 솔루션이 설치되어 시스템에 등록된 전체 자동차 수를 의미합니다.",
      en: "‘Cumulative No. of Vehicles’ refers to a total number of vehicles with a mobility solution registered in the system.",
      id: "‘Kumulatif Jumlah Kendaraan’ mengacu pada jumlah total kendaraan dengan solusi mobilitas yang terdaftar dalam sistem.",
    },
  "1년차 자동차 수는 모빌리티 솔루션이 설치되어 최근 1년내에 시스템에 등록된 자동차 수를 의미합니다.":
    {
      ko: "1년차 자동차 수는 모빌리티 솔루션이 설치되어 최근 1년내에 시스템에 등록된 자동차 수를 의미합니다.",
      en: "‘No. of Vehicles on Year 1’ represents the number of vehicles with a mobility solution registered in the system for the past year.",
      id: "'TIDAK. kendaraan pada tahun 1 'mewakili jumlah kendaraan dengan solusi mobilitas yang terdaftar dalam sistem selama setahun terakhir.",
    },
  "자동차 현황": {
    ko: "자동차 현황",
    en: "Vehicle Status",
    id: "Status kendaraan",
  },
  "자동차 수": {
    ko: "자동차 수",
    en: "No. of Cars",
    id: "Jumlah mobil",
  },
  "커넥티드 설치": {
    ko: "커넥티드 설치",
    en: "Install Connected",
    id: "Instal terhubung",
  },
  "자동차 수는 모빌리티 솔루션이 설치되어 등록되어 운영 중인 전체 자동차 수를 의미합니다.":
    {
      ko: "자동차 수는 모빌리티 솔루션이 설치되어 등록되어 운영 중인 전체 자동차 수를 의미합니다.",
      en: "‘No. of Cars’ means a total number of vehicles registered and operated with a mobility solution.",
      id: "'TIDAK. mobil berarti jumlah total kendaraan yang terdaftar dan dioperasikan dengan solusi mobilitas.",
    },
  만: {
    ko: "만",
    en: "__ years old",
    id: "__ tahun",
  },
  "{value}분 미만": {
    ko: "{value}분 미만",
    en: "Less than {value} min.",
    id: "Kurang dari {value} min.",
  },
  파트너공지사항: {
    ko: "파트너공지사항",
    en: "Notices for Partners",
    id: "Pemberitahuan untuk mitra",
  },
  "파트너 정보": {
    ko: "파트너 정보",
    en: "Partner Information",
    id: "Informasi pasangan",
  },
  관리자정보: {
    ko: "관리자정보",
    en: "Admin Information",
    id: "Informasi Admin",
  },
  엔지니어정보: {
    ko: "엔지니어정보",
    en: "Engineer Information",
    id: "Informasi Insinyur",
  },
  제어이력: {
    ko: "제어이력",
    en: "Control History",
    id: "Riwayat kontrol",
  },
  주행이력: {
    ko: "주행이력",
    en: "Driving Data",
    id: "Data mengemudi",
  },
  "주행이력 상세화면": {
    ko: "주행이력 상세화면",
    en: "Driving Data Detailed Page",
    id: "Mengemudi Halaman Data Data",
  },
  공유이력: {
    ko: "공유이력",
    en: "Share History",
    id: "Bagikan Sejarah",
  },
  공지사항: {
    ko: "공지사항",
    en: "Notices",
    id: "Pemberitahuan",
  },
  "파트너 공지사항": {
    ko: "파트너 공지사항",
    en: "Partner Notices",
    id: "PEMBERITAHUAN MITRA",
  },
  "운영 정보 관리": {
    ko: "운영 정보 관리",
    en: "Operating Information Management",
    id: "Manajemen Informasi Operasi",
  },
  "관리자 정보": {
    ko: "관리자 정보",
    en: "Admin Information",
    id: "Informasi Admin",
  },
  "자동차 정보": {
    ko: "자동차 정보",
    en: "Vehicle Information",
    id: "Informasi kendaraan",
  },
  사용이력: {
    ko: "사용이력",
    en: "History",
    id: "Sejarah",
  },
  "비밀번호 변경": {
    ko: "비밀번호 변경",
    en: "Change Password",
    id: "Ganti kata sandi",
  },
};
